import './styles.scss';

import { Col, Form, Grid, Input, Row } from 'antd';
import clsx from 'clsx';
import FormatPrice from 'components/FormatPrice';
import { useCheckoutContext } from 'contexts/checkout-context';
import usePermission from 'hooks/usePermission';
import { MODULE_CART_CHECKOUT_RIGHTS } from 'libs/constants/modulerights';
import { checkObjectIsEmpty } from 'libs/utils/common';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectCartData } from 'store/selectors/cartSelector';

import CostSummary from '../CostSummary';

function LowerSection({ form }) {
  const { t } = useTranslation();

  const { md } = Grid.useBreakpoint();

  const {
    currencyUnit,
    cartTotal,
    voucherValue,
    checkoutSettings,
    vat,
    cartTotalVAT,
  } = useSelector(selectCartData);

  const shippingNotes = checkoutSettings?.shippingNotes;
  const { isShippingNotesMandatory } = useCheckoutContext();
  const { hasPermission: hasViewIndividualPricePermission } = usePermission(
    MODULE_CART_CHECKOUT_RIGHTS.VIEW_INDIVIDUAL_PRICE
  );

  const renderShippingNotes = useCallback(() => {
    return (
      shippingNotes && (
        <Col
          className={clsx('order-overview__lower-section__left-part', {
            'fw-container': !md,
          })}
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
        >
          <Form.Item
            className="custom-form-item order-overview__lower-section__left-part__input"
            name="shippingNotesStep3"
            label={t('checkout.step3.lowerSection.shippingNotes.title')}
            rules={[
              {
                message: t(
                  'checkout.step3.lowerSection.shippingNotes.isRequired'
                ),
                required: isShippingNotesMandatory,
              },
            ]}
          >
            <Input.TextArea
              placeholder={t(
                'checkout.step3.lowerSection.shippingNotes.description'
              )}
              allowClear
              rows={4}
              onChange={(event) => {
                form.setFieldsValue({
                  shippingNotes: event.target.value,
                });
              }}
            />
          </Form.Item>
        </Col>
      )
    );
  }, [md, t, form, shippingNotes, isShippingNotesMandatory]);

  const costSummaryColumnAttributes = useMemo(() => {
    return checkoutSettings?.shippingNotes
      ? { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 }
      : {
          span: 24,
          md: { span: 12, push: 12 },
        };
  }, [checkoutSettings.shippingNotes]);

  const renderPriceSection = useCallback(
    (title, price) => (
      <Row>
        <Col
          className="order-overview__lower-section__bottom-bar__title"
          xs={14}
          sm={14}
          md={14}
          lg={14}
          xl={14}
        >
          {title}
        </Col>
        <Col
          className="order-overview__lower-section__bottom-bar__price"
          xs={10}
          sm={10}
          md={10}
          lg={10}
          xl={10}
        >
          <FormatPrice price={price} currencyUnit={currencyUnit} />
        </Col>
      </Row>
    ),
    [currencyUnit]
  );

  const shouldRenderVoucherSavings = () => {
    const isVoucherValueEmpty = checkObjectIsEmpty(voucherValue);
    const isVoucherDiscountEqualZero =
      !isVoucherValueEmpty && +voucherValue?.totalDiscount === 0;

    if (isVoucherValueEmpty || isVoucherDiscountEqualZero) {
      return null;
    }

    return (
      <Row>
        <Col
          className="order-overview__lower-section__bottom-bar__title"
          xs={14}
          sm={14}
          md={14}
          lg={14}
          xl={14}
        >
          {/* {t('checkout.step3.upperSection.rightPart.total')} */}
          {t('voucher.fields.voucherCode')}
          <div>{voucherValue?.code}</div>
        </Col>
        <Col
          hidden={!hasViewIndividualPricePermission}
          className="order-overview__lower-section__bottom-bar__price voucher-value"
          xs={10}
          sm={10}
          md={10}
          lg={10}
          xl={10}
        >
          <FormatPrice
            price={voucherValue?.totalDiscount}
            currencyUnit={currencyUnit}
          />
        </Col>
      </Row>
    );
  };

  return (
    <section className="order-overview__lower-section__wrapper">
      <Row>
        {renderShippingNotes()}
        <Col
          hidden={!hasViewIndividualPricePermission}
          className="order-overview__lower-section__right-part"
          {...costSummaryColumnAttributes}
        >
          <CostSummary subtitleRight={md} form={form} />
        </Col>
      </Row>
      <Row
        hidden={!hasViewIndividualPricePermission}
        className="order-overview__lower-section__bottom-bar"
      >
        {md && <Col xs={24} sm={24} md={12} lg={12} xl={12} />}
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          {renderPriceSection(
            t('checkout.step3.upperSection.rightPart.total'),
            cartTotal
          )}
          {shouldRenderVoucherSavings()}

          {checkoutSettings?.includingVAT && (
            <>
              {renderPriceSection(t('vat'), vat)}
              {renderPriceSection(t('totalWithVat'), cartTotalVAT)}
            </>
          )}
        </Col>
      </Row>
    </section>
  );
}

LowerSection.propTypes = {
  form: PropTypes.shape({
    getFieldValue: PropTypes.func,
    setFieldsValue: PropTypes.func,
  }).isRequired,
};

LowerSection.defaultProps = {};

export default LowerSection;
