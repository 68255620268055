import './styles.scss';

import CustomButton from 'components/CustomButton';
import { ChevronLeftIcon } from 'components/Icons';
import PermissionWrapper from 'HOCs/permissionWrapper';
import usePermission from 'hooks/usePermission';
import {
  MODULE_CART_CHECKOUT_RIGHTS,
  MODULE_SHOP_NAVIGATION_RIGHTS,
} from 'libs/constants/modulerights';
import { linkGenerator } from 'libs/utils/language';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectIsAvoidProceedingCart } from 'store/selectors/cartSelector';

import RedeemVoucher from '../RedeemVoucher';
import CartListMobile from './CartListMobile';
import CartSummaryMobile from './CartSummaryMobile';

const CartMobile = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const {
    showAddQuickEntry,
    isAddBtnDisabled,
    proceedToCheckoutHandler,
    isLoading,
    isShowQuickEntry,
  } = props;

  const isAvoidProceedingCart = useSelector(selectIsAvoidProceedingCart);
  const { hasPermission: hasViewIndividualPricePermission } = usePermission(
    MODULE_CART_CHECKOUT_RIGHTS.VIEW_INDIVIDUAL_PRICE
  );

  return (
    <div className="container-full-width-page">
      <div className="cart__wrapper">
        <div className="full-width-page-component__wrapper cart__heading">
          <h1>{t('cart.header')}</h1>
        </div>
        <CartListMobile isLoading={isLoading} />

        {/* quick entry section */}
        {isShowQuickEntry && (
          <PermissionWrapper
            permission={
              MODULE_SHOP_NAVIGATION_RIGHTS.ADD_PRODUCT_WITH_QUICK_ENTRY
            }
          >
            <div className="cart__add-button">
              <CustomButton
                onClick={showAddQuickEntry}
                disabled={isAddBtnDisabled || isAvoidProceedingCart}
                type="ghost"
                className="cart-heading__add"
                block
              >
                {t('cart.addQuickEntryBtn')}
              </CustomButton>
            </div>
          </PermissionWrapper>
        )}
        {hasViewIndividualPricePermission && (
          <CartSummaryMobile isLoading={isLoading} />
        )}

        {/* proceed to checkout page button */}
        {isLoading ? null : (
          <div style={{ marginTop: 30 }}>
            <CustomButton
              onClick={proceedToCheckoutHandler}
              block
              disabled={isAvoidProceedingCart}
            >
              {t('cart.proceedToCheckout')}
            </CustomButton>
          </div>
        )}

        <div
          className="cart-bottom__action__link"
          style={{ marginTop: 30, width: '100%' }}
        >
          <CustomButton
            type="ghost"
            className="cart-bottom__action__link--with-icon"
            onClick={() => history.push(linkGenerator('/my-workspace'))}
            block
          >
            <ChevronLeftIcon />
            {t('cart.continueShopping')}
          </CustomButton>
        </div>
      </div>
      <PermissionWrapper
        permission={MODULE_CART_CHECKOUT_RIGHTS.ADD_VOUCHER_TO_CART}
      >
        <RedeemVoucher isDisabled={isAvoidProceedingCart} />
      </PermissionWrapper>
    </div>
  );
};

CartMobile.propTypes = {
  showAddQuickEntry: PropTypes.func,
  isAddBtnDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  proceedToCheckoutHandler: PropTypes.func,
  isShowQuickEntry: PropTypes.bool,
};

CartMobile.defaultProps = {
  showAddQuickEntry: () => {},
  isAddBtnDisabled: false,
  isLoading: false,
  proceedToCheckoutHandler: () => {},
  isShowQuickEntry: false,
};

export default CartMobile;
