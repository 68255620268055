import { Col, Empty, Row } from 'antd';
import AddToCartBtn from 'components/AddToCartBtn';
import B2becPagination from 'components/B2becPagination';
import ProductComparisonModal from 'components/ProductComparisonModal';
import B2becCardSkeleton from 'components/Skeletons/B2becCardSkeleton';
import WishlistProductCard from 'components/Wishlist/WishlistProductCard';
import { useDebounce } from 'hooks';
import useProductComparison from 'hooks/useProductComparison';
import { PAGE_SIZE } from 'libs/constants';
import { isValidArray } from 'libs/utils/array';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectIsDeletingWishlist,
  selectIsRemovingWishlistProduct,
  selectIsSharedWishlistDetail,
  selectIsWishlistLoading,
  selectWishlistProducts,
  selectWishlistTotalProduct,
} from 'store/selectors/wishlistSelectors';
import { addProductToCart } from 'store/slices/cartSlice';
import {
  getWishlistDetails,
  removeWishlistProduct,
  updateProductQuantity,
} from 'store/slices/wishlistSlice';

import usePermission from '../../../../../hooks/usePermission';
import { MODULE_SHOP_NAVIGATION_RIGHTS } from '../../../../../libs/constants/modulerights';
import WishlistSearchBar from '../SearchBar';
import styles from './ProductList.module.scss';

const ProductList = ({ wishlistId, wishlistOwnerId }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const searchQuery = useDebounce(searchTerm, 300);

  const productList = useSelector(selectWishlistProducts);
  const totalProduct = useSelector(selectWishlistTotalProduct);
  const isFetchingWishlist = useSelector(selectIsWishlistLoading);
  const isDeletingWishlist = useSelector(selectIsDeletingWishlist);
  const isRemovingProduct = useSelector(selectIsRemovingWishlistProduct);
  const isSharedWishlist = useSelector(selectIsSharedWishlistDetail);

  const { hasPermission: canViewListPrice } = usePermission(
    MODULE_SHOP_NAVIGATION_RIGHTS.VIEW_LIST_PRICE
  );

  const {
    onCompareHandler,
    comparingProductIds,
    showComparisonModal,
    setShowComparisonModal,
    productInformation,
  } = useProductComparison();

  useEffect(() => {
    if (wishlistOwnerId && wishlistId)
      dispatch(
        getWishlistDetails({
          wishlistOwnerId,
          wishlistId,
          pageNumber: currentPage,
          pageSize: PAGE_SIZE[12],
          searchQuery,
        })
      );
  }, [dispatch, wishlistOwnerId, wishlistId, currentPage, searchQuery]);

  const onRemoveProduct = useCallback(
    (materialNumber) => {
      if (wishlistOwnerId && wishlistId && !isSharedWishlist) {
        dispatch(
          removeWishlistProduct({ wishlistOwnerId, wishlistId, materialNumber })
        );
      }
    },
    [wishlistOwnerId, wishlistId, isSharedWishlist, dispatch]
  );

  const onChangeProductQuantity = useCallback(
    (productItem) => (newQuantity) => {
      if (
        parseInt(newQuantity, 10) > 0 &&
        wishlistOwnerId &&
        wishlistId &&
        !isSharedWishlist
      ) {
        const productData = { ...productItem, quantity: newQuantity };

        dispatch(
          updateProductQuantity({
            wishlistOwnerId,
            wishlistId,
            productData,
          })
        );
      }
    },
    [wishlistOwnerId, wishlistId, isSharedWishlist, dispatch]
  );

  const onChangePageHandler = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  const onAddProductToCart = useCallback(
    (materialNumber, quantity) => {
      dispatch(addProductToCart({ materialNumber, quantity }));
    },
    [dispatch]
  );

  const onCheckHandler = useCallback(
    (product, event) => {
      const isChecked = event?.target?.checked;

      onCompareHandler(isChecked, product);
    },
    [onCompareHandler]
  );

  const onSearchProductHandler = useCallback((e) => {
    setSearchTerm(e?.target?.value);
  }, []);

  return (
    <>
      <div className={styles['header-container']}>
        <div className={styles['total-products']}>
          <h2>
            {t('wishlist.details.totalProduct', {
              count: totalProduct ?? 0,
            })}
          </h2>
        </div>
        <div className={styles['search-product']}>
          <WishlistSearchBar
            searchTerm={searchTerm}
            onSearchHandler={onSearchProductHandler}
          />
        </div>
      </div>
      {isFetchingWishlist || isDeletingWishlist ? (
        <B2becCardSkeleton
          gutter={20}
          columns={{ xs: 24, sm: 24, md: 12, lg: 8, xl: 8, xxl: 6 }}
          imageHeight={530}
          hasPrice={false}
          number={8}
        />
      ) : (
        <Row
          gutter={20}
          className="product-card__wrapper"
          style={{ marginTop: 30 }}
        >
          {isValidArray(productList) ? (
            productList.map((item) => (
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={8}
                xl={8}
                xxl={6}
                key={`${item?.materialNumber || item?.materialId}-${
                  item?.salesOrderNumber
                }`}
                id={`${item?.materialNumber || item?.materialId}-${
                  item?.salesOrderNumber
                }`}
                className="card-item"
              >
                <WishlistProductCard
                  name={item?.name}
                  img={item?.img || item?.imageUrl || item?.pictureUrl}
                  materialNumber={item?.materialNumber || item?.materialId}
                  listPrice={item?.listPrice || item?.price}
                  currencyUnit={item?.currencyUnit || item?.currency}
                  requiresInstruction={item?.requiresInstruction}
                  addToCartBtn={
                    <AddToCartBtn
                      handleClick={() => {
                        onAddProductToCart(
                          item?.materialNumber || item?.materialId,
                          item?.quantity
                        );
                      }}
                    />
                  }
                  comparingProductIds={comparingProductIds}
                  onCheckHandler={onCheckHandler}
                  isCompareCheckboxDisplayed
                  displayAddToCart={item?.displayAddToCart}
                  shouldDisplayListPrice={
                    item?.displayListPrice && canViewListPrice
                  }
                  quantity={item?.quantity}
                  onRemoveWishlistProductHandler={() =>
                    onRemoveProduct(item?.materialNumber || item?.materialId)
                  }
                  onChangeProductQuantityHandler={(newQuantity) =>
                    onChangeProductQuantity(item)(newQuantity)
                  }
                  isSharedWishlist={isSharedWishlist}
                  isRemovingProduct={isRemovingProduct}
                />
              </Col>
            ))
          ) : (
            <Empty
              description={t('noData')}
              className={styles['empty-result']}
            />
          )}
        </Row>
      )}

      <B2becPagination
        total={totalProduct}
        current={currentPage}
        onPageChange={onChangePageHandler}
        pageSize={PAGE_SIZE[12]}
      />

      <ProductComparisonModal
        showModal={showComparisonModal}
        setShowModal={setShowComparisonModal}
        productsData={productInformation}
      />
    </>
  );
};

export default ProductList;

ProductList.propTypes = {
  wishlistId: PropTypes.string,
  wishlistOwnerId: PropTypes.string,
};

ProductList.defaultProps = {
  wishlistId: '',
  wishlistOwnerId: '',
};
