import './styles.scss';

import { Table } from 'antd';
import B2becTable from 'components/B2becTable';
import FormatPrice from 'components/FormatPrice';
import useDummyItems from 'hooks/useDummyItems';
import { isValidArray } from 'libs/utils/array';
import { linkGenerator } from 'libs/utils/language';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  selectCartData,
  selectCheckoutSetting,
  selectHasMoqProduct,
  selectIsLoadingAfterRedeemingVoucher,
} from 'store/selectors/cartSelector';
import { replaceCartItems, setCartItemQuantity } from 'store/slices/cartSlice';

import usePermission from '../../../hooks/usePermission';
import {
  MODULE_CART_CHECKOUT_RIGHTS,
  MODULE_SHOP_NAVIGATION_RIGHTS,
} from '../../../libs/constants/modulerights';
import tableColumns from './TableColumns';

const CartItemTable = ({ isLoading, cartDataValue, isInsideSupportArea }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const isLoadingAfterRedeemingVoucher = useSelector(
    selectIsLoadingAfterRedeemingVoucher
  );
  const cartDataFromStore = useSelector(selectCartData);
  const cartData = !isInsideSupportArea ? cartDataFromStore : cartDataValue;
  const shouldHideCartTotalPrice = useSelector(selectHasMoqProduct);
  const { hasPermission: hasDeleteProductFromCartPermission } = usePermission(
    MODULE_SHOP_NAVIGATION_RIGHTS.DELETE_PRODUCT_FROM_CART
  );
  const { hasPermission: hasViewIndividualPricePermission } = usePermission(
    MODULE_CART_CHECKOUT_RIGHTS.VIEW_INDIVIDUAL_PRICE
  );

  const checkoutSettingFromStore = useSelector(selectCheckoutSetting);
  const {
    includingVAT: isIncludingVAT,
    headLvlConditions: headLevelConditions,
    positionLvlConditions: shouldShowRecyclingFee,
  } = !isInsideSupportArea
    ? checkoutSettingFromStore
    : cartDataValue?.checkoutSettings;

  const { items: cartItems, voucherValue } = cartData;

  const shouldDisplayVoucherLevel = useCallback(
    ({ discount }) => {
      let isApplied = false;
      if (voucherValue) {
        const { categoryIds, materialNumbers } = voucherValue;
        if (!isValidArray(categoryIds) && !isValidArray(materialNumbers)) {
          return false;
        }
        isApplied = discount && +discount > 0;
      }
      return isApplied;
    },
    [voucherValue]
  );

  const fakeProducts = useDummyItems(3);

  const handleCellClick = (materialNumber) => {
    if (materialNumber && typeof materialNumber === 'string') {
      history.push(linkGenerator(`/product/${materialNumber}`));
    }
  };

  const confirmReplacementHandler = useCallback(
    (oldMaterialNumber, replacedMaterialNumber, quantity) => {
      dispatch(
        replaceCartItems({
          oldMaterialNumber,
          replacedMaterialNumber,
          quantity,
        })
      );
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    [dispatch]
  );

  const confirmMoqHandler = useCallback(
    (e, record) => {
      e.stopPropagation();
      dispatch(setCartItemQuantity(record));
    },
    [dispatch]
  );

  const tableRowClasses = (record) => {
    if (record?.oldMaterialNumber || record?.originalMaterial) {
      return 'border-section--lower';
    }
    if (record?.replacedByMaterialNumber || record?.bonusMaterial) {
      return 'border-section--upper';
    }
    if (record?.hasMinQuantity) {
      return 'minimum-quantity-section';
    }
    // normal product
    return '';
  };

  const cartRowKey = (record) => {
    if (record?.oldMaterialNumber) {
      return `${record?.materialNumber}-${record?.oldMaterialNumber}`;
    }
    return [record?.materialNumber, record?.itemCateg].join('|');
  };

  const renderAppliedVoucher = useCallback(() => {
    const { code: voucherCode, totalDiscount } = voucherValue || {};

    return voucherCode ? (
      <Table.Summary.Row className="total-section">
        <Table.Summary.Cell colSpan={5}>
          <div>{t('voucher.fields.voucherCode')}</div>
          <div>{voucherCode}</div>
        </Table.Summary.Cell>
        <Table.Summary.Cell>
          {isLoading || isLoadingAfterRedeemingVoucher ? (
            <Skeleton />
          ) : (
            <FormatPrice
              price={totalDiscount}
              currencyUnit={cartData?.currencyUnit}
              className="voucher-code"
            />
          )}
        </Table.Summary.Cell>
      </Table.Summary.Row>
    ) : null;
  }, [
    t,
    isLoading,
    voucherValue,
    cartData.currencyUnit,
    isLoadingAfterRedeemingVoucher,
  ]);

  const tableSummary = () => {
    if (!hasViewIndividualPricePermission) {
      return <></>;
    }
    return (
      <>
        {!!headLevelConditions && (
          <Table.Summary.Row>
            <Table.Summary.Cell colSpan={5}>
              <span>{t('cart.table.additionalCost')}</span>
            </Table.Summary.Cell>
            <Table.Summary.Cell>
              {isLoading || isLoadingAfterRedeemingVoucher ? (
                <Skeleton />
              ) : (
                <FormatPrice
                  price={cartData?.additionalCost}
                  currencyUnit={cartData?.currencyUnit}
                />
              )}
            </Table.Summary.Cell>
          </Table.Summary.Row>
        )}

        <Table.Summary.Row>
          <Table.Summary.Cell colSpan={5}>
            <span>{t('cart.table.subtotal')}</span>
          </Table.Summary.Cell>
          <Table.Summary.Cell>
            {isLoading || isLoadingAfterRedeemingVoucher ? (
              <Skeleton />
            ) : !shouldHideCartTotalPrice ? (
              <FormatPrice
                price={cartData?.cartSubTotal}
                currencyUnit={cartData?.currencyUnit}
              />
            ) : null}
          </Table.Summary.Cell>
        </Table.Summary.Row>

        <Table.Summary.Row>
          <Table.Summary.Cell colSpan={5}>
            <span>{t('cart.table.shippingCosts')}</span>
          </Table.Summary.Cell>
          <Table.Summary.Cell>
            {isLoading || isLoadingAfterRedeemingVoucher ? (
              <Skeleton />
            ) : (
              <FormatPrice
                price={cartData?.shippingCosts}
                currencyUnit={cartData?.currencyUnit}
              />
            )}
          </Table.Summary.Cell>
        </Table.Summary.Row>
        <Table.Summary.Row className="total-section">
          <Table.Summary.Cell colSpan={5} className="boldText">
            <span>{t('total')}</span>
          </Table.Summary.Cell>
          <Table.Summary.Cell className="boldText">
            {isLoading || isLoadingAfterRedeemingVoucher ? (
              <Skeleton />
            ) : !shouldHideCartTotalPrice ? (
              <FormatPrice
                price={cartData?.cartTotal}
                currencyUnit={cartData?.currencyUnit}
              />
            ) : null}
          </Table.Summary.Cell>
        </Table.Summary.Row>
        {renderAppliedVoucher()}
        {/* show vat and total with vat */}
        {isIncludingVAT && (
          <>
            <Table.Summary.Row className="total-section vat">
              <Table.Summary.Cell colSpan={5} className="boldText">
                <span>{t('vat')}</span>
              </Table.Summary.Cell>
              <Table.Summary.Cell className="boldText">
                {isLoading || isLoadingAfterRedeemingVoucher ? (
                  <Skeleton />
                ) : (
                  <FormatPrice
                    price={cartData?.vat}
                    currencyUnit={cartData?.currencyUnit}
                  />
                )}
              </Table.Summary.Cell>
            </Table.Summary.Row>
            <Table.Summary.Row className="total-section">
              <Table.Summary.Cell colSpan={5} className="boldText">
                <span>{t('totalWithVat')}</span>
              </Table.Summary.Cell>
              <Table.Summary.Cell className="boldText">
                {isLoading || isLoadingAfterRedeemingVoucher ? (
                  <Skeleton />
                ) : !shouldHideCartTotalPrice ? (
                  <FormatPrice
                    price={cartData?.cartTotalVAT}
                    currencyUnit={cartData?.currencyUnit}
                  />
                ) : null}
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </>
        )}
      </>
    );
  };

  return (
    <B2becTable
      className="cart-table"
      columns={tableColumns({
        isLoading,
        handleCellClick,
        shouldShowRecyclingFee,
        t,
        confirmReplacementHandler,
        isInsideSupportArea,
        confirmMoqHandler,
        shouldDisplayVoucherLevel,
        isLoadingAfterRedeemingVoucher,
        currencyUnit: cartData?.currencyUnit,
        hasDeleteProductFromCartPermission,
        hasViewIndividualPricePermission,
      })}
      dataSource={isLoading ? fakeProducts : cartItems}
      style={{ width: '100%' }}
      pagination={false}
      rowKey={cartRowKey}
      rowClassName={tableRowClasses}
      summary={tableSummary}
    />
  );
};

CartItemTable.propTypes = {
  isLoading: PropTypes.bool,
};

CartItemTable.defaultProps = {
  isLoading: false,
};

export default CartItemTable;
