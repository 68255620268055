import './styles.scss';

import { Grid } from 'antd';
import clsx from 'clsx';
import usePermission from 'hooks/usePermission';
import { MODULE_CART_CHECKOUT_RIGHTS } from 'libs/constants/modulerights';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import CustomTable from '../../../../components/B2becTable';
import {
  selectCartData,
  selectIsFetchingCart,
  selectProductReferences,
} from '../../../../store/selectors/cartSelector';
import { selectUserCurrency } from '../../../../store/selectors/userSelector';
import CartItemList from '../Mobile/CartItemList';
import tableColumns from './tableColumns';

function MiddleSection() {
  const { t } = useTranslation();
  const { md } = Grid.useBreakpoint();

  const isLoading = useSelector(selectIsFetchingCart);
  const listData = useSelector(selectCartData);
  const productReferences = useSelector(selectProductReferences);
  const currency = useSelector(selectUserCurrency);
  const { hasPermission: hasViewIndividualPricePermission } = usePermission(
    MODULE_CART_CHECKOUT_RIGHTS.VIEW_INDIVIDUAL_PRICE
  );

  const newDataSource = useMemo(() => {
    const items = listData?.items;
    const newItems = items.map((item) => {
      const matchProduct = productReferences.find(
        (product) => product.materialNumber === item.materialNumber
      );
      const matchProductReference = matchProduct?.productReference;
      return {
        ...item,
        productReference: matchProductReference || '',
      };
    });
    return newItems || [];
  }, [productReferences, listData]);

  const currencyUnit = listData?.currencyUnit || currency;

  const columns = useMemo(() => {
    const col = tableColumns(currencyUnit);
    if (!hasViewIndividualPricePermission) {
      return col.filter(
        (column) => column.key !== 'price' && column.key !== 'total'
      );
    }

    return col;
  }, [currencyUnit, hasViewIndividualPricePermission]);

  return (
    <section className="order-overview__middle-section">
      <h2
        className={clsx(
          'order-overview__middle-section__title',
          !md && 'fw-container'
        )}
      >
        {t('checkout.step3.middleSection.title')}
      </h2>
      {md ? (
        <CustomTable
          className="order-overview__middle-section__table"
          columns={columns}
          dataSource={newDataSource}
          style={{ width: '100%' }}
          pagination={false}
          rowKey="materialNumber"
          footer={() => ''}
        />
      ) : (
        <CartItemList
          isLoading={isLoading}
          listData={newDataSource}
          currencyUnit={listData?.currencyUnit || currency}
        />
      )}
    </section>
  );
}

export default MiddleSection;
