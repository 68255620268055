import './styles.scss';

import clsx from 'clsx';
import FormatPrice from 'components/FormatPrice';
import { InfoIcon } from 'components/Icons';
import useDeviceDetect from 'hooks/useDeviceDetect';
import usePermission from 'hooks/usePermission';
import { MODULE_CART_CHECKOUT_RIGHTS } from 'libs/constants/modulerights';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import { selectCheckoutSetting } from 'store/selectors/cartSelector';

import CartItemName from '../../CartItemName';
import CartItemQuantityWrapper from '../../CartItemTable/CartItemQuantityWrapper';
import RemoveCartItem from '../../RemoveCartItem';

const CartItemInvalid = (props) => {
  const { t } = useTranslation();
  const { cartItem, isLoading } = props;
  const { isExtremeSmallMobile } = useDeviceDetect();

  const {
    price,
    total,
    error,
    emptyRow,
    materialNumber,
    imgUrl,
    currencyUnit,
  } = cartItem;

  const { hasPermission: hasViewIndividualPricePermission } = usePermission(
    MODULE_CART_CHECKOUT_RIGHTS.VIEW_INDIVIDUAL_PRICE
  );

  const { positionLvlConditions: shouldShowRecyclingFee } = useSelector(
    selectCheckoutSetting
  );

  const renderTopBlock = () => {
    const classes = clsx(
      'cart-item-mobile__block',
      'cart-item-mobile__right',
      'cart-item-mobile__right--full-width',
      emptyRow && 'cart-item-mobile__right--remove-margin'
    );

    const renderName = () => {
      return isLoading ? (
        <Skeleton />
      ) : (
        <CartItemName
          data={cartItem}
          shouldShowRecyclingFee={shouldShowRecyclingFee}
          isMobile
        />
      );
    };

    const renderMaterialNumber = () => {
      if (isLoading) {
        return <Skeleton />;
      }

      return error ? (
        <>
          <InfoIcon style={{ verticalAlign: 'middle' }} /> ${materialNumber}
        </>
      ) : (
        materialNumber
      );
    };

    return (
      <div className={classes}>
        <div className="cart-item-mobile__left__top">{imgUrl}</div>
        <div className="cart-item-mobile__right__top">
          {emptyRow ? null : (
            <span className="cart-item-mobile__title">{renderName()}</span>
          )}
          <span className="cart-item-mobile__item-number">
            {emptyRow ? null : `${t('cart.table.itemNo')}: `}
            {renderMaterialNumber()}
          </span>
        </div>
        <RemoveCartItem
          materialNumber={materialNumber}
          className={clsx(
            emptyRow && 'cart-item-mobile__remove-button',
            'cart-item-mobile__remove-button--empty-row'
          )}
          haveText={false}
        />
      </div>
    );
  };

  const renderListPriceBlock = () => {
    return (
      <div className="cart-item-mobile__price-block cart-item-mobile__list-price">
        <span className="cart-item-mobile__list-price__text">
          {t('cart.table.individualPrice')}
        </span>
        <span className="cart-item-mobile__list-price__value">
          {isLoading ? (
            <Skeleton />
          ) : (
            <FormatPrice price={price} currencyUnit={currencyUnit} />
          )}
        </span>
      </div>
    );
  };

  const renderTotalPriceBlock = () => {
    return (
      <div className="cart-item-mobile__price-block cart-item-mobile__total-price">
        <span className="cart-item-mobile__total-price__text">
          {t('total')}
        </span>
        <span className="cart-item-mobile__total-price__value">
          {isLoading ? (
            <Skeleton />
          ) : (
            <FormatPrice price={total} currencyUnit={currencyUnit} />
          )}
        </span>
      </div>
    );
  };

  const renderBottomLeftBlock = () => {
    const classes = clsx(
      'cart-item-mobile__block',
      isExtremeSmallMobile
        ? 'cart-item-mobile__quantity-section--empty-row cart-item-mobile__bottom'
        : 'cart-item-mobile__left'
    );

    const mainContent = (
      <CartItemQuantityWrapper isParentDisabled={!!error || emptyRow} />
    );
    return (
      <div className={classes}>
        <div className="cart-item-mobile__left__bottom">
          {isLoading ? <Skeleton /> : mainContent}
        </div>
      </div>
    );
  };

  return (
    <div className="cart-item-mobile__wrapper">
      {renderTopBlock()}
      {renderBottomLeftBlock()}
      <div
        className={clsx(
          'cart-item-mobile__block',
          isExtremeSmallMobile
            ? 'cart-item-mobile__bottommm'
            : 'cart-item-mobile__right'
        )}
      >
        {hasViewIndividualPricePermission && (
          <div className="cart-item-mobile__right__bottom">
            {renderListPriceBlock()}
            {renderTotalPriceBlock()}
          </div>
        )}
      </div>
    </div>
  );
};

CartItemInvalid.propTypes = {
  cartItem: PropTypes.shape({
    imgUrl: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    materialNumber: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({}),
      PropTypes.number,
    ]),
    quantity: PropTypes.number,
    price: PropTypes.number,
    total: PropTypes.number,
    error: PropTypes.string,
    emptyRow: PropTypes.bool,
    deliveryData: PropTypes.arrayOf(PropTypes.shape({})),
    currencyUnit: PropTypes.string,
  }),
  isLoading: PropTypes.bool,
};
CartItemInvalid.defaultProps = {
  cartItem: {},
  isLoading: false,
};

export default CartItemInvalid;
