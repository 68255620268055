import { createSlice } from '@reduxjs/toolkit';

const rentalSlice = createSlice({
  name: 'rental',
  initialState: {
    isFetching: false,
    rentableList: [],
    totalCount: 0,
    addedRentableProductList: {},
  },
  reducers: {
    getRentableProductList(state) {
      state.isFetching = true;
    },
    getRentableProductListSuccess(state, { payload }) {
      state.isFetching = false;
      state.rentableList = payload.items;
      state.totalCount = payload.totalCount;
    },
    getRentableProductListError(state) {
      state.isFetching = false;
      state.totalCount = 0;
    },
    deleteProductFromRentableList(state) {},
    deleteProductFromRentableListSuccess(state) {},
    addProductToRentalList(state) {},
    addProductToRentalListSuccess(state, { payload }) {
      state.addedRentableProductList[payload?.materialNumber] =
        payload?.materialNumber;
      state.totalCount += 1;
    },
    clearAddedRentableProductList(state) {
      state.addedRentableProductList = {};
    },
  },
});

export default rentalSlice.reducer;

export const {
  getRentableProductList,
  getRentableProductListSuccess,
  getRentableProductListError,
  deleteProductFromRentableList,
  deleteProductFromRentableListSuccess,
  addProductToRentalList,
  addProductToRentalListSuccess,
  clearAddedRentableProductList,
} = rentalSlice.actions;
