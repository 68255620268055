import './styles.scss';

import { ChevronRightIcon, HeartIcon } from 'components/Icons';
import NotFound from 'components/NotFound';
import useAdobeAnalysis from 'hooks/useAdobeAnalysis';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { PAGE_TYPES, TRACK_DATA } from 'libs/constants/adobeAnalytics';
import BREAKPOINTS from 'libs/constants/breakpoints';
import { getCategoriesFromProductDetail } from 'libs/utils/adobeAnalysis';
import { isValidArray } from 'libs/utils/array';
import { linkGenerator, removeLangCodesPrefix } from 'libs/utils/language';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { trackCategory } from 'store/slices/adobeAnalyticSlice';
import { getPriceBoxData, resetPriceBoxData } from 'store/slices/cartSlice';
import { productActions } from 'store/slices/productSlice';
import {
  sendProductDetail,
  showAddProductToWishlistModal,
} from 'store/slices/wishlistSlice';

import ProductComparisonModal from '../../components/ProductComparisonModal';
import PermissionWrapper from '../../HOCs/permissionWrapper';
import useBreadcrumbs from '../../hooks/useBreadcrumbs';
import useDeviceDetect from '../../hooks/useDeviceDetect';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import usePermission from '../../hooks/usePermission';
import { ACTION_STATUS, SHOP_CATEGORY_TYPE } from '../../libs/constants';
import {
  MODULE_CART_CHECKOUT_RIGHTS,
  MODULE_SHOP_NAVIGATION_RIGHTS,
  MODULE_WISHLIST_RIGHTS,
} from '../../libs/constants/modulerights';
import PRODUCT_TYPES from '../../libs/constants/productTypes';
import { getMainProductImage } from '../../libs/utils/productionDetail';
import {
  selectCurrencyUnit,
  selectDeliveryDates,
  selectHasMinQuantity,
  selectIsLoadingPriceBox,
  selectIsProductDangerous,
  selectNetPrice,
} from '../../store/selectors/cartSelector';
import {
  getIsLoadingProductDetail,
  getProductDetailData,
  getProductFetchStatus,
} from '../../store/selectors/productSelector';
import PriceBox from './PriceBox';
import ProductCarousel from './ProductCarousel';
import ProductDetail from './ProductDetails';
import useCalculateScrollbarWidth from 'hooks/useCalculateScrollbarWidth';

function ProductDetailPage(props) {
  const { title, breadcrumbs, pageId } = props;
  const { setTitle, setIsTitleTranslated } = useDocumentTitle(title);
  const [setBreadcrumbsData] = useBreadcrumbs(breadcrumbs);
  const { isIpadLandscape } = useDeviceDetect();
  const isLargerThan992 = useMediaQuery(`(min-width:${BREAKPOINTS.lg}px)`);

  const [showProductComparisonModal, setShowProductComparisonModal] =
    useState(false);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { materialNumber } = useParams();
  useCalculateScrollbarWidth();

  const { setPageInfoData } = useAdobeAnalysis(TRACK_DATA.CATEGORIES);

  const {
    materialName,
    categoryName,
    categoryPath,
    type: productType,
    images: productImages,
    isAvailable,
    requiresInstruction,
    listPrice,
    currencyUnit: productDetailCurrencyUnit,
    displayListPrice,
  } = useSelector(getProductDetailData, shallowEqual);

  const { hasPermission: hasViewListPricePermission } = usePermission(
    MODULE_SHOP_NAVIGATION_RIGHTS.VIEW_LIST_PRICE
  );

  const currencyUnit =
    useSelector(selectCurrencyUnit) || productDetailCurrencyUnit;
  const deliveryDates = useSelector(selectDeliveryDates);
  const isDangerous = useSelector(selectIsProductDangerous);
  const hasMinQuantity = useSelector(selectHasMinQuantity);
  const isLoadingProductDetail = useSelector(getIsLoadingProductDetail);
  const isLoadingPriceBox = useSelector(selectIsLoadingPriceBox);
  const actionStatus = useSelector(getProductFetchStatus);
  const price = useSelector(selectNetPrice);
  const { hasPermission: hasViewIndividualPricePermision } = usePermission(
    MODULE_CART_CHECKOUT_RIGHTS.VIEW_INDIVIUAL_PRICE
  );
  const netPrice = hasViewIndividualPricePermision ? price : 0;

  const productTypeLinkPrefix = useMemo(() => {
    const result = location.pathname.match(/^\/accessories|\/detergents/);
    return isValidArray(result) ? result[0] : '';
  }, [location.pathname]);

  const handleAddToWishlist = () => {
    dispatch(
      sendProductDetail({
        materialName,
        materialNumber,
        img: getMainProductImage(productImages),
      })
    );
    dispatch(showAddProductToWishlistModal());
  };

  useEffect(() => {
    if (materialName) {
      setPageInfoData({
        pageId,
        pageType: PAGE_TYPES.PRODUCT_DETAILS,
        pageName: `product_detail:${materialName}`,
      });
    }
  }, [pageId, setPageInfoData, materialName]);

  useEffect(() => {
    dispatch(productActions.getProductDetailsByLanguage(materialNumber));
    dispatch(
      getPriceBoxData({
        materialNumber,
      })
    );

    return () => {
      dispatch(productActions.setMaterialNumberToEmpty());
      dispatch(resetPriceBoxData());
    };
  }, [dispatch, materialNumber]);

  useEffect(() => {
    if (isValidArray(categoryPath)) {
      const categories = getCategoriesFromProductDetail(categoryPath);
      dispatch(trackCategory(categories));
    }
  }, [categoryPath, dispatch]);

  useEffect(() => {
    if (materialName) {
      setIsTitleTranslated(true);
      setTitle(materialName);
    }

    const breadcrumbsData = [];

    const updatePathBasedOnCategory = (type) => {
      const productTypePath = `/${type}`;

      // Example: prefix already added '/detergents'
      // when changing to new product with category '/accessories'
      // The path will be '/accessories/detergents/product/...'
      if (productTypeLinkPrefix !== productTypePath) {
        // if current prefix is different with previous prefix, remove it.
        const regex = new RegExp(
          `/${SHOP_CATEGORY_TYPE.DETERGENTS}|/${SHOP_CATEGORY_TYPE.ACCESSORIES}`,
          'i'
        );
        const removedPrefixPath = location.pathname.replace(regex, '');
        // remove the language code then adding new category type of the prefix
        // or it will be    /de-at/detergent/de-at/product/1.234-567.8
        const link = `/${type}${removeLangCodesPrefix(removedPrefixPath)}`;
        window.history.replaceState(null, '', linkGenerator(link));
      } else {
        breadcrumbsData.push({
          titleData: [t(`breadcrumbTitle.${type}`)],
          pathData: [`${type}`],
        });
      }
    };

    switch (productType) {
      case PRODUCT_TYPES.ACCESSORY:
        updatePathBasedOnCategory(SHOP_CATEGORY_TYPE.ACCESSORIES);
        break;
      case PRODUCT_TYPES.DETERGENT:
        updatePathBasedOnCategory(SHOP_CATEGORY_TYPE.DETERGENTS);
        break;
      default:
        break;
    }
    if (categoryPath) {
      switch (categoryPath.length) {
        case 1:
          breadcrumbsData.push(
            {}, // Category Overview
            {
              // Product Overview
              titleData: [categoryPath[0].name],
              pathData: [categoryPath[0].id, categoryPath[0].id], // Same id
            },
            {} // Product Overview with class selected
          );
          // Example result:
          // breadcrumbsData = [
          //   {},
          //   {
          //     titleData: ['Outdoor Power Equipment'],
          //     pathData: ['11023641', '11023641'],
          //   },
          //   {},
          // ];
          break;
        case 2:
          breadcrumbsData.push(
            {
              // Category Overview
              titleData: [categoryPath[0].name],
              pathData: [categoryPath[0].id],
            },
            {
              // Product Overview
              titleData: [categoryPath[1].name],
              pathData: [categoryPath[0].id, categoryPath[1].id],
            },
            {} // Product Overview with class selected
          );
          // Example result:
          // breadcrumbsData = [
          //   {
          //     titleData: ['Pressure Washer'],
          //     pathData: ['11051607'],
          //   },
          //   {
          //     titleData: ['Hot Water Pressure Washer'],
          //     pathData: ['11051607', '11051613'],
          //   },
          //   {},
          // ];
          break;
        case 3:
          breadcrumbsData.push(
            {
              // Category Overview
              titleData: [categoryPath[0].name],
              pathData: [categoryPath[0].id],
            },
            {
              // Product Overview
              titleData: [categoryPath[1].name],
              pathData: [categoryPath[0].id, categoryPath[1].id],
            },
            {
              // Product Overview with class selected
              titleData: [categoryPath[2].name],
              pathData: [
                categoryPath[0].id,
                categoryPath[1].id,
                categoryPath[2].id,
              ],
            }
          );
          // Example result:
          // breadcrumbsData = [
          //   {
          //     titleData: ['Pressure Washers'],
          //     pathData: ['11051607'],
          //   },
          //   {
          //     titleData: ['Hot Water Pressure Washer'],
          //     pathData: ['11051607', '11051613'],
          //   },
          //   {
          //     titleData: ['Super class'],
          //     pathData: ['11051607', '11051613', '11051616'],
          //   },
          // ];
          break;
        default:
          break;
      }
    }
    breadcrumbsData.push({
      titleData: [materialName],
      pathData:
        productTypeLinkPrefix === '/accessories' ||
        productTypeLinkPrefix === '/detergents'
          ? [productTypeLinkPrefix.replace('/', ''), materialNumber]
          : [materialNumber],
    });
    setBreadcrumbsData(breadcrumbsData);
  }, [
    setIsTitleTranslated,
    setTitle,
    productTypeLinkPrefix,
    t,
    history,
    location.pathname,
    materialNumber,
    productType,
    categoryPath,
    setBreadcrumbsData,
    materialName,
  ]);

  const renderProductNameArea = () => {
    return (
      <div className="product-details__title full-width-page-component__wrapper">
        <div className="product-details__title__container">
          <div className="product-details__title__item--left">
            <h2>
              {isLoadingProductDetail ? (
                <Skeleton width={120} height={30} />
              ) : (
                (categoryPath && categoryPath[0]?.name) || categoryName
              )}
            </h2>
            <h1>
              {isLoadingProductDetail ? (
                <Skeleton width={170} height={40} />
              ) : (
                materialName
              )}
            </h1>
            <p className="product-details__title__item-number">
              {isLoadingProductDetail ? (
                <Skeleton width={180} height={20} />
              ) : (
                <>
                  {t('productDetails.itemNo')}: {materialNumber}
                </>
              )}
            </p>
          </div>
          <div className="product-details__title__item--right">
            {isLargerThan992 && (
              <>
                <PermissionWrapper
                  permission={MODULE_SHOP_NAVIGATION_RIGHTS.PRODUCT_COMPARISION}
                >
                  <button
                    type="button"
                    className="compare-product-btn"
                    onClick={() => setShowProductComparisonModal(true)}
                  >
                    <ChevronRightIcon />
                    {t('productComparison.button')}
                  </button>
                </PermissionWrapper>
                <PermissionWrapper
                  permission={
                    MODULE_WISHLIST_RIGHTS.ADD_PRODUCT_CHANGE_WISHLIST
                  }
                >
                  <button
                    type="button"
                    className="button-as-link"
                    onClick={handleAddToWishlist}
                  >
                    <HeartIcon className="add-to-wishlist__button" />
                  </button>
                </PermissionWrapper>
              </>
            )}
          </div>
        </div>
        {!isIpadLandscape && <br />}
        <ProductComparisonModal
          showModal={showProductComparisonModal}
          setShowModal={setShowProductComparisonModal}
          productsData={{
            materialNumber,
            materialName,
            categoryName,
            price: netPrice || listPrice,
            currencyUnit,
            image: getMainProductImage(productImages),
            displayListPrice: displayListPrice && hasViewListPricePermission,
          }}
        />
      </div>
    );
  };

  if (actionStatus === ACTION_STATUS.FAILED) {
    return <NotFound />;
  }

  return (
    <div className="product-details__page__wrapper">
      {/* Product Name Area */}
      {isIpadLandscape && renderProductNameArea()}

      <div className="product-details__top__wrapper">
        {/* Product Carousel Area */}
        {isLoadingProductDetail ? (
          <div className="product-details__carousel product-details__carousel--loading">
            <Skeleton height={397} />
          </div>
        ) : (
          <ProductCarousel dataSrc={productImages} />
        )}

        {/* Price Box Area */}
        <div className="price-box__container">
          <PriceBox
            isLoading={isLoadingPriceBox || isLoadingProductDetail}
            isAvailable={isAvailable || true}
            materialNumber={materialNumber}
            currencyUnit={currencyUnit || undefined}
            listingPrice={listPrice}
            price={netPrice}
            deliveryDates={deliveryDates}
            requiresInstruction={requiresInstruction}
            materialName={materialName}
            isDangerous={isDangerous}
            hasMinQuantity={hasMinQuantity}
            shouldDisplayListPrice={
              displayListPrice && hasViewListPricePermission
            }
          />
        </div>
      </div>

      {/* Product Name Area */}
      {!isIpadLandscape && renderProductNameArea()}

      {/* Product Detail Area */}
      <ProductDetail
        isLoading={isLoadingProductDetail}
        materialNumber={materialNumber}
      />
    </div>
  );
}

ProductDetailPage.propTypes = {
  title: PropTypes.string.isRequired,
  pageId: PropTypes.string.isRequired,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default ProductDetailPage;
