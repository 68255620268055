import { Avatar } from 'antd';
import B2becTable from 'components/B2becTable';
import B2becTranslation from 'components/B2becTranslation';
import B2BLink from 'components/B2BLink';
import PropTypes from 'prop-types';
import React from 'react';
import Skeleton from 'react-loading-skeleton';

import RentalModal from '../../RentalPage/RentalModal';
import styles from './ProductsTable.module.scss';

export const ProductsTable = ({
  productsList,
  currentPage,
  isLoading,
  onSortChange,
}) => {
  const columns = createColumns(isLoading, currentPage);

  return (
    <B2becTable
      className={styles.wrapper}
      columns={columns}
      dataSource={productsList}
      rowKey="materialNumber"
      showSorterTooltip={false}
      boxShadow={false}
      onChange={onSortChange}
    />
  );
};

const createColumns = (isLoading, currentPage) =>
  [
    {
      dataIndex: 'imgUrl',
      width: '10%',
      align: 'right',
      render: (text, { materialNumber }) =>
        isLoading ? (
          <Skeleton />
        ) : (
          <B2BLink to={`/product/${materialNumber}`} state={{ currentPage }}>
            <Avatar size={48} shape="square" src={text} />
          </B2BLink>
        ),
    },
    {
      title: () => <B2becTranslation value="table.title.productName" />,
      dataIndex: 'name',
      width: '25%',
      render: (text, { materialNumber }) =>
        isLoading ? (
          <Skeleton />
        ) : (
          <B2BLink to={`/product/${materialNumber}`} state={{ currentPage }}>
            <span>{text}</span>
          </B2BLink>
        ),
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: <B2becTranslation value="table.title.materialNumber" />,
      dataIndex: 'materialNumber',
      width: '15%',
      render: (text, { materialNumber }) =>
        isLoading ? (
          <Skeleton />
        ) : (
          <B2BLink to={`/product/${materialNumber}`} state={{ currentPage }}>
            <span>{text}</span>
          </B2BLink>
        ),
    },
    {
      title: () => <B2becTranslation value="table.title.category" />,
      dataIndex: 'category',
      width: '20%',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      defaultSortOrder: 'ascend',
      render: (text, { materialNumber }) =>
        isLoading ? (
          <Skeleton />
        ) : (
          <B2BLink to={`/product/${materialNumber}`} state={{ currentPage }}>
            <span>{text}</span>
          </B2BLink>
        ),
    },
    {
      title: () => <B2becTranslation value="table.title.subCategory" />,
      dataIndex: 'subCategory',
      width: '20%',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text, { materialNumber }) =>
        isLoading ? (
          <Skeleton />
        ) : (
          <B2BLink to={`/product/${materialNumber}`} state={{ currentPage }}>
            <span>{text}</span>
          </B2BLink>
        ),
    },
    {
      width: '10%',
      render: (_, { name }) => {
        return isLoading ? (
          <Skeleton />
        ) : (
          <RentalModal deviceName={name}>
            <button className="button-as-link" type="button">
              <B2becTranslation value="rental.requestRentalInquiry" />
            </button>
          </RentalModal>
        );
      },
    },
  ].filter((col) => !col.hidden);

ProductsTable.propTypes = {
  productsList: PropTypes.oneOfType([
    PropTypes.shape({
      imgUrl: PropTypes.string,
      name: PropTypes.string,
      materialNumber: PropTypes.string,
      category: PropTypes.string,
      subCategory: PropTypes.string,
    }),
    PropTypes.arrayOf(PropTypes.object),
  ]),
  isLoading: PropTypes.bool,
  currentPage: PropTypes.number,
  onSortChange: PropTypes.func.isRequired,
};

ProductsTable.defaultProps = {
  productsList: [],
  currentPage: 1,
  isLoading: false,
};
