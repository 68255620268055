export const IPAD_LANDSCAPE_WIDTH = 1180;
export const SMALL_DEVICE_WIDTH = 640;
export const VERY_SMALL_DEVICE_WIDTH = 480;
export const EXTREME_SMALL_DEVICE_WIDTH = 375;

export const STATUS_CODE = {
  SUCCESS: 200,
  CREATED_SUCCESS: 201,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  UNSUPPORTED_MEDIA_TYPE: 405,
  INTERNAL_SERVER_ERROR: 500,
  NOT_IMPLEMENTED: 501,
  SERVICE_UNAVAILABLE: 503,
};

export const ACTION_STATUS = {
  DEFAULT: 'default',
  SUCCESS: 'success',
  FAILED: 'failed',
};

export const PAGE_SIZE = {
  10: 10,
  12: 12,
  4: 4,
};

// carousel
export const TOTAL_PRODUCT_PER_SECTION = 6;
export const PRODUCT_LIMIT_PER_SECTION_SIZE = 3;

// search
export const SEARCH_RESULTS_PER_SECTION_SIZE = 3;
export const ALL_SEARCH_RESULTS_PER_SECTION = 6;

export const ALL_SEARCH_RESULTS_PER_TYPE = 12;
export const SUITABLE_PRODUCTS_PER_PAGE = 12;

export const categoryNames = [
  {
    key: 'machine',
    en: ['machine', 'machines'],
    de: ['Geräte'],
  },
  {
    key: 'detergent',
    en: ['detergent', 'detergents'],
    de: ['Reinigungsmittel'],
  },
  {
    key: 'accessory',
    en: ['accessory', 'accessories'],
    de: ['Zubehör', 'Zubehöre'],
  },
];

export const CATEGORY_TYPE = {
  0: ['machine', 'machines'],
  1: ['accessory', 'accessories'],
  2: ['detergent', 'detergents'],
};

export const ASYNC_STATUS = {
  IDLE: 'idle',
  SUCCESS: 'success',
  ERROR: 'error',
  PENDING: 'pending',
};

export const BUTTON_TYPE = {
  PRIMARY: 'primary',
  GHOST: 'ghost',
  ACTION: 'action',
  DEFAULT: 'default',
};

export const DEFAULT_SERVICE_REQUEST_FORM_VALUES = (countryId) => ({
  deliveryAddress: {
    country: countryId,
  },
});

export const SERVICE_DELIVERY_ADDRESS_RADIO_VALUE = {
  FIRST_OPTION: 0,
  ADD_NEW_ADDRESS_OPTION: -1,
};

export const SERVICE_STEP = {
  SELECT_MACHINE: 'selectMachine',
  SELECT_SERVICE: 'selectService',
  SELECT_ADDRESS_n_DATE: 'addressAndDate',
  SERVICE_OVERVIEW: 'serviceOverview',
};

export const SERVICE_ENTRY = {
  REPAIR: 'repair',
  MAINTENANCE: 'maintenance',
  MACHINE: 'machine',
};

export const SERVICE_TYPE = {
  REPAIR: 'repair',
  MAINTENANCE: 'maintenance',
  INSPECTION: 'Inspection',
  REMOTE_SUPPORT: 'Remote Support',
};

export const SHOP_CATEGORY_TYPE = {
  MACHINES: 'machines',
  ACCESSORIES: 'accessories',
  DETERGENTS: 'detergents',
};

export const SERVICE_CASE_PRIORITY = {
  URGENT: 1,
  HIGH: 2,
  NORMAL: 3,
};

export const INVALID_TYPES = {
  EXCLUDED: 'Excluded Material Number',
  INVALID: 'Invalid Material Number',
};

export const DEFAULT_PROMOTION_ITEMS = [{}, {}, {}, {}, {}, {}];

export const isValidMaterial = (str) =>
  RegExp('^([0-9]{1}).([0-9]{3})-([0-9]{3}).([0-9]{1})').test(str);

export const SERVICE_PACKAGES_TYPES = {
  INSPECT: 'karcherInspect',
  MAINTAIN: 'karcherMaintain',
  FULLSERVICE: 'karcherFullService',
};

export const ALL_SERVICE_PAGES_TYPES = [
  SERVICE_PACKAGES_TYPES.INSPECT,
  SERVICE_PACKAGES_TYPES.MAINTAIN,
  SERVICE_PACKAGES_TYPES.FULLSERVICE,
];

export const PRODUCT_MATCHING_TYPES = {
  ALL: 'all',
  DETERGENTS: 'detergents',
  ACCESSORIES: 'accessories',
  SUITABLE: 'suitableProducts',
};

export const COOKIE_SAMESITE_ATTRIBUTES = {
  STRICT: 'Strict',
  LAX: 'Lax',
};

export const USER_FORM_TYPES = {
  CREATE: 'create',
  EDIT: 'edit',
  INVITE: 'invite',
  SELF_REGISTER: 'selfRegister',
};

export const SORT_FIELDS = {
  lastName: 'lastName',
  companyName: 'companyName',
};

export const SORT_ORDERS = {
  ascend: 'asc',
  descend: 'desc',
};

export const ACCEPTED_UPLOAD_FILE_EXTENSIONS =
  'application/vnd.ms-excel,text/csv,.csv';

export const USER_ROLES = {
  KaercherAdmin: 'KaercherAdmin',
  KaercherUser: 'KaercherUser',
  Customer: 'Customer',
  Employee: 'Employee',
};

export const SESSION_BASED_EXPIRY_TIME = 1000 * 3600 * 1;

export const COUNTRY_BY_SALESORG = {
  DE50: 'DE',
  DE20: 'DE',
  AT10: 'AT',
  CH10: 'CH',
  UK10: 'GB',
  FR15: 'FR',
  NO10: 'NO',
  PL10: 'PL',
  NL10: 'NL',
  CZ20: 'CZ',
  DK10: 'DK',
  FI10: 'FI',
  HU20: 'HU',
  JP10: 'JP',
  BE10: 'BE',
  EE10: 'EE',
  SE10: 'SE',
  RO10: 'RO',
  AU10: 'AU',
  CA10: 'CA',
  RO20: 'RO',
  US60: 'US',
};

export const isFutureTech = (salesOrg) => salesOrg === 'DE20';

export const IMAGE_SOURCES = {
  ACCESSORIES:
    'https://s1.kaercher-media.com/media/image/selection/1550/d3/accessory.webp',
  DETERGENTS:
    'https://s1.kaercher-media.com/media/image/selection/46310/d3/detergents.webp',
};
