import { createSelector } from '@reduxjs/toolkit';
import { isValidArray } from 'libs/utils/array';

export const getIsLoadingSubmenuProductCategoryList = (state) =>
  state?.category?.isLoading;
export const selectSubmenuProductCategories = (state) =>
  state.category?.productCategories;
export const selectSubmenuCategories = createSelector(
  selectSubmenuProductCategories,
  (productCategories) =>
    isValidArray(productCategories) ? productCategories?.slice(0, 1).pop() : {}
);
export const selectSubmenuAccessoriesAndDetergents = createSelector(
  selectSubmenuProductCategories,
  (productCategories) =>
    isValidArray(productCategories)
      ? productCategories?.slice(1, productCategories?.length)
      : []
);
export const getSubmenuProductCategoryFetchStatus = (state) =>
  state?.category?.getProductSubmenuCategoryFetchStatus;

export const getProductOverviewData = (state) => state.category.productOverview;
export const selectIsLoadingSubCategoryOverview = (state) =>
  state?.category?.isLoadingSubCategoryOverview;
export const selectSubCategoryOverviewData = (state) =>
  state?.category?.subCategoryOverviewData;
